@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,700;1,800&display=swap");
html,
body {
  font-family: "Raleway", sans-serif;
  background-color: #fff;
}

a:hover {
  text-decoration: none;
}

body {
  top: 5px !important;
}

.navbarCustom {
  background-color: #5baf1e !important;
  -webkit-box-shadow: 0 0px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0px 30px rgba(0, 0, 0, 0.1);
}

.navbarCustom .navbar-toggler {
  background-color: #fff;
}

.navbarCustom .navbar-nav .nav-item .nav-link {
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  letter-spacing: 1px;
}

.navbarCustom .navbar-nav .nav-item.active .nav-link,
.navbarCustom .navbar-nav .nav-item:hover .nav-link {
  color: yellow;
}

.navbarCustom .navbar-nav .dropdown .dropdown-menu {
  padding: 0;
  top: 59px;
  background-color: #5baf1e;
  border: 0;
  border-radius: 0;
}

.navbarCustom .navbar-nav .dropdown .dropdown-menu .dropdown-item {
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  color: #fff;
  font-weight: 400;
  padding: 10px 20px;
}

.navbarCustom .navbar-nav .dropdown .dropdown-menu .dropdown-item:hover {
  background-color: yellow;
  color: black;
}

.homeBanner {
  position: relative;
}

.homeBanner .content {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  padding-top: 20%;
}

.homeBanner .content .contentCenter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
}

.homeBanner .content .contentCenter h1 {
  font-family: "Raleway", sans-serif;
  font-size: 40px;
  color: #fff;
  font-weight: 500;
  text-align: center;
}

.homeBanner .content .contentCenter p {
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  color: #fff;
  font-weight: 500;
  text-align: center;
}

.mainContnet {
  padding: 30px 0;
  padding-bottom: 235px;
  min-height: calc(100vh - 175px);
  position: relative;
}

.mainContnet p {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  color: #393939;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 20px;
}

.mainContnet ul.list {
  padding: 0;
  margin: 0;
}

.mainContnet ul.list li {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  color: #393939;
  font-weight: 500;
  margin-bottom: 10px;
  list-style-type: circle;
  list-style-position: inside;
}

.mainContnet ol.numList {
  padding: 0;
  margin: 0;
}

.mainContnet ol.numList li {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  color: #393939;
  font-weight: 500;
  margin-bottom: 10px;
  list-style-position: outside;
  padding-left: 10px;
  margin-left: 15px;
}

.mainContnet a {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  color: #000000;
  font-weight: 500;
  text-decoration: underline;
}

.mainContnet a:hover {
  text-decoration: none;
}

.mainContnet .videoPart {
  border: solid 1px #ededed;
  padding: 20px;
}

.mainContnet .aboutImg {
  float: right;
  display: table;
  margin: 0.5em 0 0.5em 1em;
}

.mainContnet .book img {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 0 1px 1px 0;
  -webkit-box-shadow: 1px -1px 0 #dcdcdc, 3px -3px 0 #d1d1d1, 4px -4px 0 #999,
    2px -2px 0 #200f3c, 5px 5px 15px rgba(0, 0, 0, 0.3);
  box-shadow: 1px -1px 0 #dcdcdc, 3px -3px 0 #d1d1d1, 4px -4px 0 #999,
    2px -2px 0 #200f3c, 5px 5px 15px rgba(0, 0, 0, 0.3);
}

.mainContnet .book figcaption {
  font-size: 0.8em;
  opacity: 0.75;
  margin: 1em 0;
  text-align: center;
}

.mainContnet .btnCenter {
  margin: 0 auto;
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.mainContnet .note {
  border: 2px solid #fcde65;
  border-radius: 4px;
  background-color: #fcf7e3;
  margin: 10px 0;
  padding: 4px 10px;
}

.btnPrimary {
  background-color: #5baf1e;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 500;
  border-radius: 30px;
  padding: 10px 20px;
  min-width: 100px;
  color: white !important;
  text-decoration: none !important;
}

.btn:hover {
  background-color: yellow !important;
  color: black !important;
}

.btnSecondary {
  background-color: #5baf1e;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  color: white;
  font-weight: 500;
  border-radius: 30px;
  padding: 10px 20px;
  min-width: 100px;
  color: white !important;
  text-decoration: none !important;
}

.btnSecondary:hover {
  background-color: yellow !important;
  color: black !important;
}

.btnWhite {
  background-color: #fff;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  color: #5baf1e;
  font-weight: 500;
  border-radius: 30px;
  padding: 10px 20px;
  min-width: 100px;
  border: solid 1px #5baf1e;
}

.btnWhite:hover {
  background-color: #5baf1e;
  border: solid 1px #5baf1e;
}

.btnBSuccess {
  background-color: #00aa00;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  border-radius: 30px;
  padding: 5px 10px;
  min-width: 80px;
  border: 0;
}

.btnBSuccess:hover {
  background-color: #00aa00;
}

.btnEdit {
  background-color: #00aa00;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  border-radius: 30px;
  padding: 5px 10px;
  min-width: 80px;
  border: 0;
}

.btnDanger {
  background-color: #cf0101;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  border-radius: 30px;
  padding: 5px 10px;
  min-width: 80px;
  border: 0;
}

.btnDanger:hover {
  background-color: #cf0101;
}

.bgWhite {
  background-color: #fff;
}

.bgPrimary {
  background-color: #5baf1e;
}

.bgDark {
  background-color: #1e494f;
}

.py-80 {
  padding: 80px 0;
}

.icon svg path {
  fill: #000;
}

.mt120 {
  margin-top: 120px;
}

.mb80 {
  margin-bottom: 80px;
}

.mt50 {
  margin-top: 50px;
}

.mt30 {
  margin-top: 30px;
}

h1 {
  font-family: "Raleway", sans-serif;
  font-size: 32px;
  color: #5baf1e;
  font-weight: 500;
}

h2 {
  font-family: "Raleway", sans-serif;
  font-size: 26px;
  color: #5baf1e;
  font-weight: 500;
}

p {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: 400;
}

.colorWhite {
  color: #fff;
}

.font18 {
  font-size: 18px;
  color: #5baf1e;
}

.latestSermonsBox {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  height: 100%;
}

.latestSermonsBox .title {
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  color: #000;
  font-weight: 700;
  margin: 20px 0 10px 0;
}

.latestSermonsBox p {
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  color: #000;
  font-weight: 400;
}

footer {
  padding: 3px 16px;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
}

footer p {
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  color: #fff;
  font-weight: 300;
  margin-bottom: 10px;
  color: #fff !important;
}

.heroBanner {
  margin-top: 75px;
  padding: 50px 0;
}

.heroBanner h2 {
  font-family: "Raleway", sans-serif;
  font-size: 40px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 10px;
}

.heroBanner .videoFrame {
  background-color: #fff;
  padding: 15px;
  border-radius: 30px;
  -webkit-box-shadow: 0 0px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0px 30px rgba(0, 0, 0, 0.1);
}

.coursePart {
  background-color: #1e494f;
  padding: 30px;
  border-radius: 10px;
}

.coursesBox {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  height: 100%;
}

.coursesBox .courseBoxImg {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background-color: #fff;
  margin: 0 auto;
}

.coursesBox .courseBoxImg img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
}

.coursesBox .title {
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  color: #000;
  font-weight: 700;
  margin: 20px 0 10px 0;
}

.coursesBox p {
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  color: #000;
  font-weight: 400;
}

.list ul {
  padding: 0;
  margin: 0;
}

.list ul li {
  list-style-type: none;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
}

.list ul li span {
  width: 40px;
}

.list ul li span img {
  width: 24px;
}

.socialMediaBox {
  background-color: #fff;
  padding: 50px 15px;
  border-radius: 10px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.socialMediaBox .icon img {
  width: 100px;
}

.socialMediaBox .title {
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  color: #000;
  font-weight: 700;
  margin-top: 30px;
}

.socialMediaBox:hover {
  background-color: #5baf1e;
}

.socialMediaBox:hover .title {
  color: #fff;
  text-decoration: none !important;
}

.methodBG {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.imgFrame {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.loginMain {
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.loginMain .login .logo {
  margin-bottom: 10px;
}

.loginMain .login .loginBox {
  background-color: #5baf1e;
  padding: 15px;
  border-radius: 10px;
}

label {
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  color: #f5f5f5;
  font-weight: 400;
  margin-bottom: 5px;
}

.fontBlk {
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  color: #000;
  font-weight: 400;
}

.input {
  background-color: #fff;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  border-radius: 30px;
  border: solid 1px #5baf1e;
}

.input:focus {
  color: #000;
}

.linkWhite {
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
}

.linkWhite:hover {
  color: #000000;
}

.linkSecondary {
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  color: #000000;
  font-weight: 500;
}

.linkSecondary:hover {
  color: #fff;
}

.step-indicator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.step {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.step-indicator .step-icon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: #c2c2c2;
  font-size: 10px;
  text-align: center;
  color: #ffffff;
  position: relative;
  line-height: 50px;
  font-size: 20px;
}

.step.active .step-icon {
  background: #5baf1e;
}

.step p {
  text-align: center;
  position: absolute;
  bottom: -40px;
  color: #c2c2c2;
  font-size: 14px;
  font-weight: bold;
}

.step.active p {
  color: #5baf1e;
}

.step.step2 p,
.step.step3 p {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.indicator-line {
  width: 100%;
  height: 2px;
  background: #c2c2c2;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.indicator-line.active {
  background: #5baf1e;
}

@media screen and (max-width: 500px) {
  .step p {
    font-size: 11px;
    bottom: -20px;
  }
}

.stepDetails {
  margin: 50px 0;
}

.stepDetails h2 {
  font-family: "Raleway", sans-serif;
  font-size: 30px;
  color: #5baf1e;
  font-weight: 700;
}

.stepDetails h3 {
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  color: #000000;
  font-weight: 700;
  margin-bottom: 20px;
}

.stepDetails .videoPart {
  background-color: #f5f5f5;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.stepDetails .questionPart {
  border: solid 1px #f5f5f5;
  padding: 20px;
}

.stepDetails hr {
  border-top: solid 2px #f5f5f5;
}

.stepDetails .radio {
  margin-top: 10px;
}

.stepDetails .radio input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.stepDetails .radio input[type="radio"] + .radio-label:before {
  content: "";
  background: #fff;
  border-radius: 100%;
  border: 1px solid #bfbfbf;
  display: inline-block;
  width: 1.4em;
  height: 1.4em;
  position: relative;
  top: 0;
  margin-right: 1em;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
}

.stepDetails .radio input[type="radio"]:checked + .radio-label:before {
  background-color: #5baf1e;
  -webkit-box-shadow: inset 0 0 0 4px #fff;
  box-shadow: inset 0 0 0 4px #fff;
}

.stepDetails .radio input[type="radio"]:focus + .radio-label:before {
  outline: none;
  border-color: #5baf1e;
}

.stepDetails .radio input[type="radio"]:disabled + .radio-label:before {
  -webkit-box-shadow: inset 0 0 0 4px #fff;
  box-shadow: inset 0 0 0 4px #fff;
  border-color: #bfbfbf;
  background: #bfbfbf;
}

.stepDetails .radio input[type="radio"] + .radio-label:empty:before {
  margin-right: 0;
}

.stepDetails .radio label {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  color: #5baf1e;
  font-weight: 500;
}

.linkDark {
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  color: #5baf1e;
  font-weight: 700;
}

.linkDark:hover {
  color: #000000;
}

.recentEvemtPart {
  border-bottom: solid 1px #f5f5f5;
}

.recentEvemtPart .datepart .month {
  background-color: #5baf1e;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  padding: 5px;
  text-align: center;
}

.recentEvemtPart .datepart .date {
  font-family: "Raleway", sans-serif;
  font-size: 50px;
  color: #1e494f;
  font-weight: 700;
  text-align: center;
}

.recentEvemtPart .detailspart h3 {
  font-family: "Raleway", sans-serif;
  font-size: 24px;
  color: #000;
  font-weight: 700;
}

.sidebar {
  background-color: #f5f5f5;
  width: 300px;
  height: calc(100vh - 75px);
  position: fixed;
  left: 0;
  top: 75px;
  padding: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  z-index: 99;
}

.sidebar ul {
  padding: 0;
  margin: 10px 0 0 0;
  width: 100%;
}

.sidebar ul li {
  margin-bottom: 10px;
  list-style-type: none;
}

.sidebar ul li a {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  padding: 10px 20px;
  display: block;
}

.sidebar ul li a:hover,
.sidebar ul li a.active {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  text-decoration: none;
  background-color: #5baf1e;
  border-radius: 30px;
}

.nodata {
  width: 100%;
  padding: 20px;
  background-color: #f5f5f5;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: 500;
  text-align: center;
}

.profile-pic {
  color: transparent;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  transition: all 0.3s ease;
}

.profile-pic input {
  display: none;
}

.profile-pic img {
  position: absolute;
  -o-object-fit: cover;
  object-fit: cover;
  width: 165px;
  height: 165px;
  -webkit-box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
  box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
  border-radius: 100px;
  z-index: 0;
}

.profile-pic .-label {
  cursor: pointer;
  height: 165px;
  width: 165px;
  margin-bottom: 0 !important;
}

.profile-pic:hover .-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000;
  color: #fafafa;
  -webkit-transition: background-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out;
  border-radius: 100px;
  margin-bottom: 0 !important;
}

.profile-pic span {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0.2em;
  height: 2em;
}

.alertPart {
  background-color: #cf0101;
  margin-top: 75px;
  padding: 5px 0;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 500;
}

@media (max-width: 992px) {
  .sidebar {
    width: 100%;
    position: fixed;
    top: 75px;
    border-radius: 0;
    height: auto;
    z-index: 99;
  }
  .sidebar .logo {
    display: none;
  }
  .sidebar ul {
    margin: 0;
  }
}

.navbarTogglerCustom {
  background-color: #5baf1e;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.navbarTogglerCustom span {
  width: 20px;
  height: 2px;
  background-color: #5baf1e;
  margin: 4px 0;
}

.navbarTogglerCustom:hover {
  background-color: #5baf1e;
}

.navbarTogglerCustom:hover span {
  background-color: #000;
}

.mobileMenu {
  position: fixed;
  top: 75px;
  left: 0;
  width: 100%;
  background-color: #5baf1e;
  z-index: 99;
}

.mobileMenu .navbar-nav .nav-link {
  color: #fff;
  padding: 20px;
}

.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show > .nav-link {
  color: #000;
}

.userPic {
  width: 50px;
  height: 50px;
}

.userPic img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

.nav-item {
  margin-left: 10px;
}

.dropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  /* display: flex; */
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.middlePartMain {
  width: 100%;
  padding-left: 300px;
  padding-top: 75px;
}

.middlePartMain .middle {
  padding: 30px;
}

.contactForm label {
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  color: #393939;
  font-weight: 300;
}

.contactForm .form-control {
  border: solid 1px #5baf1e;
}

@media (max-width: 992px) {
  .heroSlider h1 {
    font-size: 35px;
  }
  .heroSlider h2 {
    font-size: 24px;
  }
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 18px;
  }
  .heroBanner h2 {
    font-size: 24px;
  }
  .userButton {
    padding: 0;
    border: 0;
  }
  .middlePartMain {
    padding-left: 0;
  }
  .dropdown {
    display: unset;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .homeBanner .content {
    padding-top: 10%;
  }
  .mainContnet {
    min-height: calc(100vh - 166px);
  }
}

@media (max-width: 576px) {
  .audiorowPart .audioImg {
    width: 100%;
    margin-bottom: 10px;
  }
  .audiorowPart .audioDetails {
    width: 100%;
    padding-left: 0;
  }
  .step-indicator .step-icon {
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
  }
  .step p {
    display: none;
  }
  .stepDetails {
    margin: 0;
  }
}
/*# sourceMappingURL=style.css.map */

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}

.goog-te-gadget .goog-te-combo {
  color: rgb(0, 0, 0) !important;
  font-weight: 600;
  min-width: 165px;
}


#google_translate_element img {
  display: none !important;
}

.goog-te-banner-frame {
  display: none;
  height: 0 !important;
  visibility: hidden;
}

#google_translate_element {
  display: none;
}

#goog-gt-tt {
  color: #22222205;
  /* background-color: #ffffff; */
  border: 1px solid #eee;
  box-shadow: 0 4px 16px rgb(0 0 0 / 20%);
  -moz-box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 4px 16px rgb(0 0 0 / 20%);
  display: none !important;
  font-family: arial;
  font-size: 10pt;
  width: 420px;
  padding: 12px;
  position: absolute;
  z-index: 10000;
}

.goog-text-highlight {
  background-color: transparent !important;
  box-shadow: 0 0 0 0 transparent !important;
}

.g-translate {
  position: absolute;
  z-index: 11;
  right: 7%;
  top: 15px;
}

.fas.fa-globe {
  top: 10px;
  right: 16px;
  font-size: 20px;
  position: absolute;
  color: #5baf1e;
  
}

.g-translate select {
  padding-left: 8px;
  width: 40px;
  background: white;
  box-shadow: 0px 0px 10px 0 #b1b1b17a;
  border: unset;
  border-radius: 10px;
  height: 30px;
}

@media (max-width: 768px) {
  .g-translate {
    position: absolute;
    right: 80px;
    top: 33px;
  }
}

@media (max-width: 576px) {
  .g-translate {
    position: absolute;
    right: 70px;
    top: 25px;
  }
  .menu-list {
    display: flex;
    justify-content: left;
  }

  .chapter-side-bar {
    height: auto;
  }
  

  .chapter-data-sidebar {
    display: none;
  }

  .chapter-details-box {
    align-items: center;
    gap: 20px;
    flex-direction: column;
    height: auto !important;
  }
}

@media (max-width: 450px) {
  .g-translate {
    position: absolute;
    right: 70px;
    top: 35px;
  }
}

.g-translate span {
  display: none;
}

.g-translate .skiptranslate.goog-te-gadget {
  font-size: 0;
}

.g-translate select:focus {
  box-shadow: 0px 0px 10px 0 #d20a0a7a;
}

.g-translate .svg-inline--fa {
  position: absolute;
  right: 18px;
}